$(document).ready(function(){
	var btnTriggerCopyLink = $('.button-trigger-dropdown'),
		dropdownCopyLink = $('.copy-link-dropdown'),
		body = $('body'),
		$document = $(document);

	$('.button-trigger-dropdown').on('click', function(e){
		e.stopPropagation();
		dropdownCopyLink.toggleClass('active');
		body.toggleClass('show-copy-link-dropdown');
	});
	$document.on('click', function(){
		dropdownCopyLink.removeClass('active');
		body.removeClass('show-copy-link-dropdown');
	});
});

(function($){

	$(function(){
		var status = new DropDown( $('.copy-link-section') );
	});


	function DropDown(el) {
		this.dd = el;
		this.body = $('body');
		this.btn = this.dd.children('.button-trigger-dropdown');
		this.placeholder = this.dd.find('.copy-link-name .copy-link-button');
		this.menu = this.dd.find('.copy-link-dropdown');
		this.opts = this.dd.find('.copy-link-dropdown > li');
		this.val = '';
		this.index = -1;
		this.initEvents();
		this.closeEvents();
	}
	DropDown.prototype = {
		initEvents : function() {
			var obj = this;

			obj.btn.on('click', function(event){
				obj.menu.toggleClass('active');
				obj.body.toggleClass('show-copy-link-dropdown');
				return false;
			});

			obj.opts.on('click',function(){
				var opt = $(this);
				obj.placeholder.html('');
				obj.val = opt.text();
				console.log(obj.val);
				obj.index = opt.index();
				obj.placeholder.text('Copy Request Summary - ' + obj.val);
				obj.menu.removeClass('active');
				obj.body.removeClass('show-copy-link-dropdown');
			});
		},
		getValue : function() {
			return this.val;
		},
		getIndex : function() {
			return this.index;
		},
		closeEvents : function(){
			var ths = this;
			$(document).on('click', function(){
				ths.menu.removeClass('active');
				ths.body.removeClass('show-copy-link-dropdown');
			});

			ths.menu.on('click', function(e){
				e.stopPropagation();
			});
		}
	}

})(jQuery);